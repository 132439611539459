import cx from 'classnames';
import {
  graphql,
  PageProps,
} from 'gatsby';
import React, { ReactElement, useEffect } from 'react';
import Category from '../components/Category/Category';
import CatList from '../components/CategoryList/CatList';
import PageFooter from '../components/pageFooter/pageFooter';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import { useMdData } from '../static-queries/useBlogData';
import blogTemplateStyles from './blog.module.scss';
import styles from './graphic.module.scss';

export default function BlogTemplate({
  data,
}: PageProps<{
  markdownRemark: any,
}>): ReactElement {
  const md = data.post;
  const blogData = useMdData();
  const allBlogData =
    blogData.posts.edges;
    const {setIsSideBarShown} = usePageContext()

    useEffect(() => {
      setIsSideBarShown(true)
      return () => {
      };
    }, []);
  function getNextSlug(slug: string) {
    const allSlugs = allBlogData.map(
      (blog: any) =>
        blog.node.fields.slug
    );
    const nextSlug =
      allSlugs[
        allSlugs.indexOf(slug) + 1
      ];
    if (
      nextSlug !== undefined &&
      nextSlug !== ''
    ) {
      return nextSlug;
    }
    return allSlugs[0];
  }

  const nextSlug = getNextSlug(
    md.fields.slug
  );

  return (
    <>
      <SEO
        title={md.frontmatter.title}
        description={md.excerpt}
        rrssImg={
          md.frontmatter.hero_image
            .publicURL
            ? md.frontmatter.hero_image
                .publicURL
            : ''
        }
      />

      <article
        className={cx(
          'w-full px-6 pb-6 min-h-screen flex items-center flex-col',
          blogTemplateStyles.mainContainer
        )}>
        <div
          className={
            'hidden md:block w-full'
          }>
          <CatList />
        </div>
        <section className="md:w-basic w-full md:mt-0 mt-32">
          <div
            className={cx(
              styles.blog,
              'pb-20 md:pt-10 flex items-center flex-col'
            )}>
            <div
              className={cx(
                blogTemplateStyles.blog__info,
                'justify-center flex flex-col md:px-12 md:py-10'
              )}>
              <h1 className="font-gordo mb-3 text-gray-800 tracking-wider text-2xl md:text-3xl md:leading-10">
                {md.frontmatter.title}
              </h1>
              <div
                className={'flex mb-4'}>
                <div
                  className={cx(
                    blogTemplateStyles.catsContainer,
                    'flex w-full justify-start'
                  )}>
                  {md.frontmatter.categories?.map(
                    (cat: string) => {
                      return (
                        <Category
                          key={cat}
                          text
                          category={cat}
                          size={20}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <h3 className="font-charisma text-gray-900 tracking-wide pb-10">
                {md.frontmatter.date}
              </h3>

              <hr className="py-5"></hr>
            </div>
            <div
              className={cx(
                styles.blog__body,
                'pt-5 md:px-10 md:pt-10 pb-14 w-full'
              )}
              dangerouslySetInnerHTML={{
                __html: md.html,
              }}
            />
          </div>
          <PageFooter />
        </section>
      </article>
    </>
  );
}

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query (
    $slug: String! #        $categories: [String]!
  ) {
    post: markdownRemark(
      fields: {slug: {eq: $slug}}
    ) {
      fields {
        slug
      }
      excerpt
      frontmatter {
        title
        author
        categories
        date(
          formatString: "MMMM Do, YYYY"
        )
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
      html
    }
    #        related: allMarkdownRemark(
    #            sort: { order: DESC, fields: frontmatter___date },
    #            limit: 3,
    #            filter: {
    #                frontmatter: {
    #                    categories: {in: $categories}
    #                }}
    #        ) {
    #            edges {
    #                node {
    #                    frontmatter {
    #                        title
    #                    }
    #                    fields {
    #                        slug
    #                    }
    #                }
    #            }
    #        }
  }
`;
